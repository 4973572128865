import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import './UserListPage.css'; 
import { useLocation } from 'react-router-dom';

const localeText = {

  noRowsLabel: 'Sin filas',
  noResultsOverlayLabel: 'Sin resultados',
  errorOverlayDefaultLabel: 'Ha ocurrido un error.',
  footerRowSelected: (count) => `${count} fila(s) seleccionada(s)`,
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount} de ${totalCount}`,
  columnMenuLabel: 'Menú',
  columnMenuShowColumns: 'Mostrar columnas',
  columnMenuFilter: 'Filtro',
  columnMenuHideColumn: 'Ocultar',
  columnMenuUnsort: 'Desordenar',
  columnMenuSortAsc: 'Ordenar Ascendente',
  columnMenuSortDesc: 'Ordenar Descendente',
  footerRowPerPage: 'Filas por página:',
  footerTotalRows: 'Total de filas:',

  // Paginación
  MuiTablePagination: {
    labelRowsPerPage: 'Filas por página:',
    labelDisplayedRows: ({ from, to, count }) => `${from}–${to} de ${count}`,
  }
};

const UserListPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Obtener el valor del parámetro 'server' desde la URL
  const queryParams = new URLSearchParams(location.search);
  const server = queryParams.get('server');
  const serverName = queryParams.get('servername');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (!server) {
          console.error("Servidor no seleccionado.");
          return;
        }

        const response = await fetch(`/api/characters?server=${server}`); // Enviar el servidor en la query string
        const data = await response.json();
        
        // Desanidar los campos dentro de cada jugador
        const flattenedData = data.map(player => ({
          ...player,
          ...player.stats,
          ...player.attributes,
        }));

        setUsers(flattenedData); // Cargar los datos obtenidos desde el backend
        setLoading(false); // Finalizar el loading
      } catch (error) {
        console.error('Error al obtener los personajes:', error);
        setLoading(false); // Finalizar el loading en caso de error
      }
    };

    fetchUsers(); 
  }, [server]); 


const columns = [
  { field: 'username', headerAlign: 'center', headerName: 'Nombre', width: 150 },
  { field: 'clase', headerAlign: 'center', headerName: 'Clase', width: 150 },
  { field: 'raza', headerAlign: 'center', headerName: 'Raza', width: 150 },
  { field: 'elv', headerAlign: 'center', headerName: 'Nivel', width: 150 },
  { field: 'exp', headerAlign: 'center', headerName: 'Experiencia', width: 150 },
  { field: 'elu', headerAlign: 'center', headerName: 'Elu', width: 150 },
  { field: 'gld', headerAlign: 'center', headerName: 'Oro', width: 150 },
  { field: 'eldhir', headerAlign: 'center', headerName: 'Dsp', width: 150 }
];

if (loading) {
  return <div>Cargando datos...</div>;
}
  return (
    <div className="welcome-screen" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box className="info-box">
        <h1 className="medieval-title">Estadisticas</h1>
        <p className="medieval-subtitle">Personajes registrados en el servidor '{serverName}'</p>
      </Box>

      <DataGrid
  rows={users}
  columns={columns}
  pageSize={10}
  rowsPerPageOptions={[5, 10, 20]}
  disableSelectionOnClick
  hideFooterSelectedRowCount // Elimina el texto de "row selected"
  getRowId={(row) => row.username}
  localeText={localeText}
  sx={{
    '& .MuiDataGrid-root': {
      border: 'none',
      backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo oscuro para todo el DataGrid
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#3E2723', // Tono marrón oscuro en encabezados
      color: '#E1C699', // Texto dorado suave en encabezados
      fontFamily: 'Cinzel, serif',
      fontSize: '16px',
      borderBottom: '1px solid #E1C699',
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: '#3E2723', // Fondo oscuro en los encabezados
      color: '#E1C699', // Texto dorado suave
      display: 'flex',
      justifyContent: 'center', // Centrar horizontalmente
      alignItems: 'center', // Centrar verticalmente
      textAlign: 'center', // Refuerzo para centrar texto
      padding: '0px',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      width: '100%', // Ocupar todo el ancho disponible
      display: 'flex',
      justifyContent: 'center', // Centrar horizontalmente
      alignItems: 'center', // Centrar verticalmente
      textAlign: 'center', // Texto centrado
      fontWeight: 'bold', // Mantener la negrita
    },
    '& .MuiDataGrid-sortIcon': {
      position: 'absolute',
      right: '8px', // Align the sort icon to the right
      color: '#E1C699', // Match the icon color to the header text color
    },
    '& .MuiDataGrid-cell': {
      backgroundColor: 'rgba(0, 0, 0, 0.9)', // Fondo oscuro en las celdas
      color: '#E1C699', // Texto dorado
      borderBottom: 'none', // Sin bordes internos en las celdas
      fontFamily: 'Cinzel, serif',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiDataGrid-row': {
      backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo oscuro en las filas
      borderBottom: '2px solid #E1C699', // Borde dorado suave entre filas
      '&:hover': {
        backgroundColor: 'rgba(45, 41, 42, 1)', // Fondo más oscuro al hacer hover
      },
      '&.Mui-selected': {
        backgroundColor: 'rgba(255, 215, 0, 0.2) !important', // Fondo dorado suave para filas seleccionadas
      },
      '&.Mui-selected:hover': {
        backgroundColor: 'rgba(255, 215, 0, 0.4) !important', // Hover para filas seleccionadas
      },
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: '#3E2723', // Pie de página con tono marrón oscuro
      color: '#E1C699',
      borderTop: '1px solid #E1C699',
    },
    '& .MuiDataGrid-virtualScroller': {
      backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo semitransparente en el área de scroll
    },
    '& .MuiDataGrid-overlay': {
      backgroundColor: 'rgba(35, 31, 32, 0.9)', // Fondo oscuro para superposiciones
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none', // Oculta los separadores de columnas
    },
    '& .MuiDataGrid-iconButtonContainer': {
      color: '#E1C699', // Ajusta el color de los botones de acción de las columnas
    },
    '& ::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#E1C699', // Scroll dorado suave
      borderRadius: '5px',
    },
    '& ::-webkit-scrollbar-track': {
      backgroundColor: '#222', // Fondo oscuro del scroll
    },
  }}

/>




    </div>
  );
};

export default UserListPage;
