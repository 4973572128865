const initialState = {
  isLoading: false,
  user: {},
  message: '',
  error: null,
  recoverySentMessage: '',
  pinSentMessage: '',
  passwordResetSuccessMessage: '',
  pinResetSuccessMessage: '',
  isConnected: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONNECTED':
      return {
        ...state,
        isConnected: true,
      };
    case 'SET_DISCONNECTED':
      return {
        ...state,
        isConnected: false,
      };
    case 'REQUEST_LOGIN':
      return {
        ...state,
        isLoading: true,
        user: {
          ...state.user,
          username: action.payload.username
        },
        error: null,
       
      };
      
    case 'REQUEST_REGISTER':
      return {
        ...state,
        isLoading: true,
        error: null,
      };

      case 'REQUEST_RECOVER':
        return {
          ...state,
          isLoading: true,
          error: null,
        };
        case 'REQUEST_PIN':
          return {
            ...state,
            isLoading: true,
            error: null,
          };
      case 'REQUEST_CHANGEPASSWORD':
          return {
            ...state,
            isLoading: true,
            error: null,
          };
      case 'REQUEST_CHANGEPIN':
            return {
              ...state,
              isLoading: true,
              error: null,
            };
      case 'RECOVERY_SENT':
            return {
              ...state,
              recoverySentMessage: action.payload,
            };

      case 'PIN_SENT':
              return {
                ...state,
                pinSentMessage: action.payload,
              };
      case 'PASSWORD_RESET_SUCCESS':
            return {
              ...state,
              passwordResetSuccessMessage: action.payload,
            };

      case 'PIN_RESET_SUCCESS':
              return {
                ...state,
                pinResetSuccessMessage: action.payload,
              };
          
      case 'RECEIVE_LOGIN':
        return {
          ...state,
          isLoading: false,
          isConnected: true,
          message: action.payload.message,
          type: action.payload.type,
          version: action.payload.version,
          email: action.payload.email,
          username: action.payload.username,
          error: null
        };
      case 'SAVE_PASSWORD':
        return {
          ...state,
          user: {
            ...state.user,
            password: action.payload.password // Temporalmente almacena la contraseña
          }
        };
      case 'CLEAR_PASSWORD':
        return {
          ...state,
          user: {
            ...state.user,
            password: null // Limpia la contraseña
          }
        };
    case 'RECEIVE_REGISTER':
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
        error: null,
      };
    case 'AUTH_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'RESET_AUTH_STATE':
      return initialState;

    case 'CLEAR_MESSAGES':
      return {
        ...state,
        message: '',
        error: '',
      };

    case 'RESET_LOGIN_STATE':
      return {
        ...state,
        type: null,
        message: '',
        error: null
      };

    default:
      return state;
  }
};

export default authReducer;