import React, { createContext, useContext, useState } from 'react';
import io from 'socket.io-client';
import processMessage from './messageProcessor';
import { useDispatch } from 'react-redux';
import { authError } from '../redux/actions/authActions';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [tcpConnection, setTcpConnection] = useState(null);
  const [socket, setSocket] = useState(null);  // Estado para mantener la referencia al socket

  const serverUrl = 'https://desterium.net';
  //const serverUrl = 'http://localhost:8081';
  const connectTcp = (userData, actionType = 'login') => {
    if (tcpConnection && tcpConnection.connected) {
      return;
    }
    const newSocket = io(serverUrl, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5
    });

    newSocket.on('connect', () => {
      console.log('Conectado al servidor');
      newSocket.emit('data', { type: actionType, content: userData });
    });

    newSocket.on('data', (messageData) => {
      processMessage(messageData, dispatch);
    });

    newSocket.on('error', (error) => {
      console.error('Error de conexión:', error);
      dispatch(authError(error.message));
    });

    newSocket.on('critical', (error) => {
      console.error('Error crítico recibido:', error);
      dispatch(authError(error.message));
      newSocket.disconnect(true);
    });

    newSocket.on('reconnect', (attemptNumber) => {
      console.log(`Reconnected after ${attemptNumber} attempts`);
      newSocket.emit('data', { type: actionType, content: userData });
    });

    newSocket.on('reconnect_attempt', () => {
      console.log('Attempting to reconnect...');
    });

    newSocket.on('reconnect_error', (error) => {
      console.error('Reconnection error:', error);
    });

    newSocket.on('reconnect_failed', () => {
      console.error('Reconnection failed');
    });

    setSocket(newSocket); 
    setTcpConnection(newSocket);
  };

  const emitEvent = (eventType, data) => {
    if (socket) {
      socket.emit(eventType, data);
    } else {
      console.error("Socket not connected");
    }
  };

  return (
    <GlobalContext.Provider value={{ tcpConnection, connectTcp, dispatch, emitEvent }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);