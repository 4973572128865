import React, { useState, useEffect} from 'react';
import './RegisterPage.css';
import { isValidUsername, isValidEmail, isValidPassword } from '../functions';
import { useGlobalContext } from '../connection/GlobalContext';
import { requestRegister, authError, resetLoginState, clearMessages } from '../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-recaptcha';

const RegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isVerified, setIsVerified] = useState(false);

  const [user, setUser] = useState({
    username: '',
    email: '',
    password: '',
  });

  const [validation, setValidation] = useState({
    usernameValid: null,
    emailValid: null,
    passwordValid: null,
  });

  const { type, message, error } = useSelector((state) => state.auth);
  const { connectTcp } = useGlobalContext();

  useEffect(() => {
    let clearMessagesTimer = setTimeout(() => {
      dispatch(clearMessages());
    }, 3000);
  
    let navigateTimer;
    if (type === "registerok") {
      navigateTimer = setTimeout(() => {
        navigate('/login');
        dispatch(resetLoginState());
      }, 3000);
    }
  
    return () => {
      clearTimeout(clearMessagesTimer);
      if (navigateTimer) clearTimeout(navigateTimer);
    };
  }, [type, navigate, dispatch]);
  

  const handleVerify = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };
  
  const handleRegister = () => {
    const { username, email, password } = user;
    const { usernameValid, emailValid, passwordValid } = validation;
  
    if (!usernameValid || !emailValid) {
      dispatch(authError('Comprueba los datos ingresados.'));
      return;
    }

    if (!passwordValid) {
      dispatch(authError('La contraseña debe tener 6 caracteres 1 número y 1 mayúscula.'));
      return;
    }

    dispatch(requestRegister({ username, email, password }, connectTcp));

  };


  const handleInputChange = (field) => (event) => {
    const { value } = event.target;
    setUser((prev) => ({ ...prev, [field]: value }));
  
    switch (field) {
      case 'username':
        setValidation((prev) => ({
          ...prev,
          usernameValid: isValidUsername(value),
        }));
        break;
      case 'email':
        setValidation((prev) => ({
          ...prev,
          emailValid: isValidEmail(value),
        }));
        break;
      case 'password':
        setValidation((prev) => ({
          ...prev,
          passwordValid: isValidPassword(value),
        }));
        break;
      default:
        break;
    }
  };
  
  
  return (
    
    <div className="welcome-screen" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div className="info-box">
        <h1 className="medieval-title">Registra una nueva cuenta</h1>
        <div className="input-container">
  <input
    type="text"
    placeholder="Usuario"
    value={user.username}
    onChange={handleInputChange('username')}
    className={`input-field ${user.username && (validation.usernameValid ? 'valid' : 'invalid')}`}
  />
  {user.username && (
    <span className={`validation-icon ${validation.usernameValid ? 'valid' : 'invalid'}`}>
      {validation.usernameValid ? '✓' : '✕'}
    </span>
  )}
</div>

<div className="input-container">
  <input
    type="email"
    placeholder="Email"
    value={user.email}
    onChange={handleInputChange('email')}
    className={`input-field ${user.email && (validation.emailValid ? 'valid' : 'invalid')}`}
  />
  {user.email && (
    <span className={`validation-icon ${validation.emailValid ? 'valid' : 'invalid'}`}>
      {validation.emailValid ? '✓' : '✕'}
    </span>
  )}
</div>

<div className="input-container">
  <input
    type="password"
    placeholder="Contraseña"
    value={user.password}
    onChange={handleInputChange('password')}
    className={`input-field ${user.password && (validation.passwordValid ? 'valid' : 'invalid')}`}
  />
  {user.password && (
    <span className={`validation-icon ${validation.passwordValid ? 'valid' : 'invalid'}`}>
      {validation.passwordValid ? '✓' : '✕'}
    </span>
  )}
</div>


  
        <button onClick={handleRegister} className="btn-primary">
          Registrar
        </button>
  
        {error && <div className="error-message">{error}</div>}
        {message && <div className="message success-message">{message}</div>}
      </div>
    </div>
  );
};

export default RegisterPage;
