import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authError, requestChangePin } from '../redux/actions/authActions';
import { useGlobalContext } from '../connection/GlobalContext';
import { isValidPin } from '../functions';

const ChangePinPage = () => {
  const [newPin, setNewPin] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pinResetSuccessMessage } = useSelector((state) => state.auth);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const { connectTcp } = useGlobalContext();
  const [validation, setValidation] = useState({
    pinValid: null,
  });
  const { pinValid } = validation;

  const handleChangePin = () => {
    if (!pinValid) {
      dispatch(authError('La clave PIN debe tener 6 caracteres 1 número y 1 mayúscula.'));
      return;
    }
    dispatch(requestChangePin({ token, newPin }, connectTcp));
  };


  const handleInputChange = (field) => (event) => {
    const { value } = event.target;
    setNewPin(value);
    switch (field) {
      case 'pin':
        setValidation((prev) => ({
          ...prev,
          pinValid: isValidPin(value),
        }));
        break;
      default:
        break;
    }
  };

  return (
    <div className="welcome-screen" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div className="info-box">
        <h1 className="medieval-title">Cambiar PIN</h1>
        <input
          type="passwd"
          placeholder="Nueva PIN"
          value={newPin}
          onChange={handleInputChange('pin')}
          className={`input-field ${newPin && (pinValid ? 'valid' : 'invalid')}`}
        />

        {newPin && (
          <span className={`validation-icon ${pinValid ? 'valid' : 'invalid'}`}>
            {pinValid ? '✓' : '✕'}
          </span>
        )}

        <button onClick={handleChangePin} className="btn-primary">
          Cambiar PIN
        </button>

        {pinResetSuccessMessage && <div className="message success-message">{pinResetSuccessMessage}</div>}
      </div>
    </div>
  );
};

export default ChangePinPage;

