import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../connection/GlobalContext'; 
import { useDispatch, useSelector } from 'react-redux';
import { requestRecover, receiveRecoverySent } from '../redux/actions/authActions'; 

import './LoginPage.css';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { connectTcp } = useGlobalContext();
  const dispatch = useDispatch();
  const { recoverySentMessage } = useSelector((state) => state.auth);

  const handleRecoverPassword = () => {
    // Aquí puedes agregar la lógica para enviar un correo electrónico de recuperación de contraseña
    console.log('Correo electrónico para recuperar contraseña:', email);

    // Aca requiere un recover
    dispatch(requestRecover({ email }, connectTcp));

  };

  return (
    <div className="welcome-screen" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div className="info-box">
        <h1 className="medieval-title">Recuperar Contraseña</h1>

        <input
          type="text"
          placeholder="Correo Electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
        />

        <button onClick={handleRecoverPassword} className="btn-primary">
          Recuperar
        </button>

        {recoverySentMessage && <div className="message success-message">{recoverySentMessage}</div>}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
