import React, { useState, useEffect, useRef  } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import logo from '../img/logo.png';
import userIcon from '../img/icons/user.png'; // Importa el ícono de usuario
import { useDispatch, useSelector } from 'react-redux';
import { setDisconnected } from '../redux/actions/authActions';
import { useGlobalContext } from '../connection/GlobalContext'; // Importa useGlobalContext

const Navbar = () => {
  const { tcpConnection } = useGlobalContext(); 
  const isConnected = useSelector(state => state.auth.isConnected);
  const Navigate = useNavigate (); 
  const dispatch = useDispatch();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { username } = useSelector(state => state.auth);
  const userMenuRef = useRef(null);

  const handleConnectClick = () => {
    Navigate('/login'); 
  };

  const handleRegisterClick = () => {
    Navigate('/register'); 
  };

  const handleDisconnectClick = () => {

    if (tcpConnection) {
      tcpConnection.disconnect();
    }

    dispatch(setDisconnected());
    Navigate('/'); 
    setShowUserMenu(false);
  };

  const handlePerfilClick = () => {
    Navigate('/user'); 
    setShowUserMenu(false);
  };


  const handleUserMenuClick = () => {
    setShowUserMenu(!showUserMenu);
  };

  return (
    <nav className="navbar-fixed bg-gray-800 text-white p-5">
      <div className="max-w-6xl mx-auto px-4 flex justify-between">
      <a href="/" className="h-8" onClick={(e) => { e.preventDefault(); Navigate('/'); }}>
          <img src={logo} alt="Desterium" className="h-full" />
          <div className="text-xs">Servidores Online</div>
        </a>

        <div className="flex items-center space-x-4 relative">
          {isConnected && (
              <div className="flex items-center">
             <button onClick={handleUserMenuClick} className="py-2 px-4 bg-transparent opacity-60 hover:opacity-100">
              <img src={userIcon} alt="User" className="h-11 w-11 rounded-full" />
            </button>
            <span className="text-white font-bold text-shadow-md">{username}</span>
            </div>
          )}
          {showUserMenu && isConnected && (
          <div className="absolute top-full right-0 bg-gray-800 text-white rounded-md shadow-lg mt-2">
           <button onClick={handlePerfilClick} className="block px-4 py-2 text-sm hover:bg-gray-700">Mi Perfil</button>
          <button onClick={handleDisconnectClick} className="block w-full px-4 py-2 text-sm hover:bg-gray-700">Desconectarse</button>
          </div>
          )}
          {!isConnected ? (
            <>
              <button onClick={handleConnectClick} className="py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded">
                Conectarse
              </button>
              <button onClick={handleRegisterClick} className="py-2 px-4 bg-green-500 hover:bg-green-700 text-white font-bold rounded">
                Registrarse
              </button>
            </>
          ) : (null)}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
