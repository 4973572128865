import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './UserPage.css';
import { invoke } from '@tauri-apps/api/tauri';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../connection/GlobalContext';
import { requestPin, receivePinSent } from '../redux/actions/authActions'; 

const UserPage = () => {
  const { username, password, version} = useSelector(state => state.auth);
  const [selectedGame, setSelectedGame] = useState('agite');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showDownloads, setShowDownloads] = useState(false);
  const [isTauri, setIsTauri] = useState(window.__TAURI__ !== undefined);
  const {connectTcp, emitEvent} = useGlobalContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pinSentMessage } = useSelector((state) => state.auth);

  const games = {
    'agite': {
      port: 8080,
      title: "Desterium Online (Oficial)",
      details: [
        "✅ Una descarga, múltiples mods.",
        "✅ Mod Fácil (Exp x30 | Oro x15)",
        "✅ Mod Hardcore (Exp x1 | Oro x1)",
        "✅ Mod Privado : Administra y organiza tu propio servidor.",
        "⬇️ Elige una única descarga."
      ],
      downloads: [
        { label: "Opcion 1 - DESCARGA OFICIAL", url: "https://www.desterium.com/file/DesteriumV6.exe" },
        { label: "Opcion 2 - vía MEDIAFIRE", url: "https://www.mediafire.com/file/70d23ao4h1vg0n3/DesteriumV6.exe/file" },
        { label: "Opcion 3 - vía MEGA", url: "https://mega.nz/file/vUoWUIjI#qOMRNvyT7IzWJMmDHEdT3E0soA5CepE2XN4PuaQLF18" },
        { label: "Opcion 4 - vía DRIVE", url: "https://drive.google.com/file/d/16F3qd-0KVqJEiPmEUjJaS9KRHFL8G7P9/view?usp=sharing" }
      ],
      disabled: false
    }
  };

  const extractVersion = (label) => {
    const match = label.match(/V(\d+\.\d+\.\d+)/);
    return match ? match[1] : null;
  };
  
  const compareVersions = (v1, v2) => {
    const parts1 = v1.split('.').map(Number);
    const parts2 = v2.split('.').map(Number);
  
    let i = 0;
    while (i < parts1.length || i < parts2.length) {
      const num1 = i < parts1.length ? parts1[i] : 0;
      const num2 = i < parts2.length ? parts2[i] : 0;
      
      if (num1 > num2) return 1;
      if (num1 < num2) return -1;
      i++;
    }
    return 0;
  };
  
  const sendCredentialsToVB6 = (game) => {
    if (games[game].disabled) {
      setError("Servidor no disponible");
      setTimeout(() => setMessage(''), 5000);
      return;
    }

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    fetch(`http://45.235.98.111:${games[game].port}`, {
      method: 'POST',
      body: formData,
      transports: ['websocket']
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Respuesta del servidor no fue exitosa: ${response.status}`);
      }
      const responseClone = response.clone();
      return responseClone.text(); 
    })
    .then(data => {
      const serverVersion = extractVersion(data);

      if (compareVersions(version, serverVersion) !== 0) {
        setError("Version obsoleta. Actualice por favor el juego descargando el ultimo parche.");
        setShowDownloads(true);
        setTimeout(() => setError(''), 10000);
        return;
      }

      setMessage('Operación exitosa: ' + data);
      setTimeout(() => setMessage(''), 5000);
    })
    .catch(error => {
      setError('No se pudo establecer conexión con el juego ¿Has instalado y ejecutado el juego?');
      console.log("Error en linea:", error);
      setTimeout(() => setMessage(''), 7000);
    });
  };

  const handlePlay = () => {
    if (isTauri) {
      invoke('run_game', {
        exePath: 'DesteriumClassic.exe'
      })
      .then(() => setMessage('Juego iniciado correctamente.'))
      .catch(err => setMessage('Error al iniciar el juego: ' + err));
    } else {
      sendCredentialsToVB6(selectedGame);
    }
  };
  
  
  const handleChangePin = () => {
    dispatch(requestPin(emitEvent));
  };

  const handleSupport = () => {
    window.location.href = 'https://hesk.desterium.net';
  };


  const toggleDownloads = () => {
    setShowDownloads(!showDownloads);
  };



  return (
    <div className="welcome-screen">
      <div className="profile-info-box">
        <h1 className="medieval-title">Perfil de {username}</h1>
        <p className="medieval-subtitle">Version {version}</p>
        <div className="button-container">
        <button onClick={handleChangePin} className="btn-secondary"disabled={false}>
          Cambiar PIN
        </button>

          <button onClick={handleSupport} className="btn-secondary" disabled={false}>Soporte</button>

        </div>

        {pinSentMessage && <div className="message success-message">{pinSentMessage}</div>}
        
      </div>
     
    </div>
  );
};

export default UserPage;
