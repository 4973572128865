const handlers = require('./handlers');


function processMessage(messageData, dispatch) {
    const { type, content } = messageData;
   // console.log("Procesando mensaje:", messageData);

    // Invocar el manejador correspondiente al tipo de mensaje
    if (handlers[type]) {
      //  console.log("Content antes de llamar al handler:", content);
        handlers[type](content, dispatch);
    } else {
        // messageData ya es un objeto JavaScript
       // console.log('Mensaje recibido:', messageData.type, messageData.data);
       // console.log(`Handler no encontrado para el tipo de mensaje: ${type}`);
    }
}

module.exports = processMessage;
