import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../connection/GlobalContext';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  requestLogin,
  authError,
  clearMessages,
  savePassword,
  resetLoginState,
} from '../redux/actions/authActions';

import { isValidUsername, isValidPassword } from '../functions';
import './LoginPage.css';



const LoginPage = () => {
  const [username, setUser] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {connectTcp } = useGlobalContext();
  const { type, message, error } = useSelector((state) => state.auth);

  useEffect(() => {
    let clearMessagesTimer = setTimeout(() => {
      dispatch(clearMessages());
    }, 3000);
  
    let navigateTimer;
    if (type === "loggedok") {
      navigate('/user');
      dispatch(resetLoginState());
    }
  
    return () => {
      clearTimeout(clearMessagesTimer);
      if (navigateTimer) clearTimeout(navigateTimer);
    };
  }, [type, navigate, dispatch]);
  
  
  const handleLogin = () => {
      if (!isValidUsername(username)) {
        dispatch(authError('El nombre de usuario debe tener al menos 6 caracteres y solo puede contener caracteres alfanuméricos.'));
        return;
      }
  
      if (!isValidPassword(password)) {
        dispatch(authError('La contraseña no cumple con los requisitos necesarios.'));
        return;
      }
  
      dispatch(requestLogin(username));
      dispatch(savePassword(password));
      const userData = { username: username, password: password };
      localStorage.setItem('userData', JSON.stringify(userData)); // Save user data to localStorage
      connectTcp(userData);
      console.log(username, password);
    };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };


  return (
    <div className="welcome-screen" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    <div className="info-box">
      <h1 className="medieval-title">Iniciar Sesión</h1>
      
      <input
        type="text"
        placeholder="Usuario"
        value={username}
        onChange={(e) => setUser(e.target.value)}
        className="input-field"
      />

      <input
        type="password"
        placeholder="Contraseña"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="input-field"
      />

      <button onClick={handleLogin} className="btn-primary">
        Conectar
      </button>

      <button onClick={handleForgotPassword} className="btn-secondary">
          ¿Has olvidado tu contraseña?
      </button>

      {error && <div className="message error-message">{error}</div>}
      {message && <div className="message success-message">{message}</div>}
    </div>
    </div>
  );
};

export default LoginPage;
