function isValidUsername(username) {
    return /^[a-zA-Z0-9]{6,}$/.test(username); // Solo caracteres alfanuméricos, mínimo 6 caracteres
  }
  
  function isValidEmail(email) {
    // Esta es una validación básica y puede no cubrir todos los casos válidos de emails.
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  
  function isValidPassword(password) {
    // Mínimo 6 caracteres, al menos una letra mayúscula y un número
    return /^(?=.*[A-Z])(?=.*\d).{6,}$/.test(password);
}
  

function isValidPin(password) {
  // Mínimo 6 caracteres, al menos una letra mayúscula y un número
  return /^(?=.*[A-Z])(?=.*\d).{6,}$/.test(password);
}
  module.exports = {
    isValidUsername,
    isValidEmail,
    isValidPassword,
    isValidPin
};