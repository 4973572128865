import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './DownloadPage.css';
import { useGlobalContext } from '../connection/GlobalContext';

const DownloadPage = () => {
  const { username, password, version } = useSelector(state => state.auth);
  const [selectedGame, setSelectedGame] = useState('agite');
  const [showDownloads, setShowDownloads] = useState(false);
  const { connectTcp, emitEvent } = useGlobalContext();
  
  const games = {
    'agite': {
      port: 8080,
      title: "Argentum Online",
      details: [
        "✅ Jugabilidad clásica (sin macros). Nivel máximo 47. Clases originarias.",
        "✅ Servidor Fácil (Exp x20 | Oro x10 | RESET cada 3 meses)",
        "✅ Servidor Difícil (Exp x1 | Oro x1 | NO reset)",
        "⬇️ Elige una única descarga."
      ],
      downloads: [
        { label: "Opción 1 - DESCARGA DIRECTA", url: "https://www.desterium.net/file/DesteriumV6.exe" },
        { label: "Opción 2 - vía MEDIAFIRE", url: "https://www.mediafire.com/file/70d23ao4h1vg0n3/DesteriumV6.exe/file" },
        { label: "Opción 3 - vía MEGA", url: "https://mega.nz/file/vUoWUIjI#qOMRNvyT7IzWJMmDHEdT3E0soA5CepE2XN4PuaQLF18" },
        { label: "Opción 4 - vía DRIVE", url: "https://drive.google.com/file/d/16F3qd-0KVqJEiPmEUjJaS9KRHFL8G7P9/view?usp=sharing" }
      ],
      disabled: false
    }
  };

  const toggleDownloads = () => {
    setShowDownloads(!showDownloads);
  };

  return (
    <div className="welcome-screen">
      <h1 className="medieval-title">Comenzar a Jugar</h1>
      <p className="medieval-subtitle">¡Elige el juego o servidor que más te guste!</p>

      <div className="game-container">
        <div className="select-container">
          <select id="server-select" value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)} className="server-select">
            {Object.keys(games).map(game => (
              <option key={game} value={game}>{games[game].title}</option>
            ))}
          </select>
        </div>
        <div className="game-info info-box">
          <ul className="game-details">
            {games[selectedGame].details.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
          <button className="btn-download" onClick={toggleDownloads}>
            Descargar
          </button>
          {showDownloads && (
            <div className="downloads-list">
              {games[selectedGame].downloads.map((download, index) => (
                <div key={index}>
                  <a href={download.url} target="_blank" rel="noopener noreferrer" className="download-link">{download.label}</a>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
