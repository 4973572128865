const { receiveRegister, receiveLogin, setConnected } = require('../redux/actions/authActions.js');

const handlers = {
  registerok: async (content, dispatch) => {
    try {
      const successDetails = { type: "registerok", message: "Registro Exitoso. Redigiriendo..." };
      dispatch(receiveRegister(successDetails));

     // console.log("Acción registerok llamada con mensaje:", successDetails);
    } catch (error) {
      console.error(error);
    }
  },

  loggedok: async (content, dispatch) => {
    try {
      const { username, email, credits, version } = content;
      const successDetails = { type: "loggedok", message: "Conexión exitosa. Redirigiendo al perfil del usuario.", version: version, email: email, username: username };
      dispatch(receiveLogin(successDetails));
      dispatch(setConnected());
   

      //console.log("UserName: ", username);
     // console.log("Creditos: ", credits);
      //console.log("Version: ", version);
      //console.log("Email: ", email);


    } catch (error) {
      console.error(error);
    }
  },

  recoverysent: async (content, dispatch) => {
    try {
      const { message } = content;

      dispatch({ type: 'RECOVERY_SENT', payload: message });
    } catch (error) {
      console.error(error);
    }
  },

  pinsent: async (content, dispatch) => {
    try {
      const { message } = content;

      dispatch({ type: 'PIN_SENT', payload: message });
      //dispatch(receivePinSent(message));
    } catch (error) {
      console.error(error);
    }
  },

  passwordreset: async (content, dispatch) => {
    try {
      const { message } = content;

      dispatch({ type: 'PASSWORD_RESET_SUCCESS', payload: message });
    } catch (error) {
      console.error(error);
    }
  },

  pinreset: async (content, dispatch) => {
    try {
      const { message } = content;

      dispatch({ type: 'PIN_RESET_SUCCESS', payload: message });
    } catch (error) {
      console.error(error);
    }
  },
};


module.exports = handlers;
