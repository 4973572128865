import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authError, requestChangePassword, receivePasswordResetSuccess } from '../redux/actions/authActions';
import { useGlobalContext } from '../connection/GlobalContext';
import { isValidPassword } from '../functions';

const ChangePasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { passwordResetSuccessMessage } = useSelector((state) => state.auth);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const { connectTcp } = useGlobalContext();
  const [validation, setValidation] = useState({
    passwordValid: null,
  });
  const { passwordValid } = validation;

  const handleChangePassword = () => {
    // Aquí puedes agregar la lógica para enviar el cambio de contraseña al servidor
    if (!passwordValid) {
      dispatch(authError('La contraseña debe tener 6 caracteres 1 número y 1 mayúscula.'));
      return;
    }
    dispatch(requestChangePassword({ token, newPassword }, connectTcp));
  };

  useEffect(() => {
    if (passwordResetSuccessMessage) {
      const timeoutId = setTimeout(() => {
        navigate('/login');
        dispatch(receivePasswordResetSuccess(''));
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [passwordResetSuccessMessage, dispatch, navigate]);

  const handleInputChange = (field) => (event) => {
    const { value } = event.target;
    setNewPassword(value);
    switch (field) {
      case 'password':
        setValidation((prev) => ({
          ...prev,
          passwordValid: isValidPassword(value),
        }));
        break;
      default:
        break;
    }
  };

  return (
    <div className="welcome-screen" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div className="info-box">
        <h1 className="medieval-title">Cambiar Contraseña</h1>

        <input
          type="password"
          placeholder="Nueva Contraseña"
          value={newPassword}
          onChange={handleInputChange('password')}
          className={`input-field ${newPassword && (passwordValid ? 'valid' : 'invalid')}`}
        />

        {newPassword && (
          <span className={`validation-icon ${passwordValid ? 'valid' : 'invalid'}`}>
            {passwordValid ? '✓' : '✕'}
          </span>
        )}

        <button onClick={handleChangePassword} className="btn-primary">
          Cambiar Contraseña
        </button>

        {passwordResetSuccessMessage && <div className="message success-message">{passwordResetSuccessMessage}</div>}
      </div>
    </div>
  );
};

export default ChangePasswordPage;

