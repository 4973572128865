export const requestLogin = (username) => ({
    type: 'REQUEST_LOGIN',
    payload: {username}
  });
  
export const savePassword = (password) => ({
    type: 'SAVE_PASSWORD',
    payload: {password}

});

export const clearPassword = () => ({
    type: 'CLEAR_PASSWORD'
});

export const requestRegister = (userData, connectTcp) => async (dispatch) => {
  const { username, email, password } = userData;
  try {
      dispatch({ type: 'REQUEST_REGISTER' });
      await connectTcp({ username, email, password }, 'register');
  } catch (error) {
      console.error('Error en el registro:', error);
      dispatch(authError('Error de conexión.'));
  }
};

export const requestRecover = (userData, connectTcp) => async (dispatch) => {
  const { email } = userData;
  try {
      dispatch({ type: 'REQUEST_RECOVER' });
      await connectTcp({ email }, 'recover');
  } catch (error) {
      console.error('Error en el recupero:', error);
      dispatch(authError('Error de conexión.'));
  }
};

export const requestPin = (emitEvent) => async (dispatch) => {

  try {
      dispatch({ type: 'REQUEST_PIN' });
      emitEvent('data', { type: 'pin'});
      
  } catch (error) {
      console.error('Error en el cambio de pin:', error);
      dispatch(authError('Error de conexión.'));
  }
};

export const requestChangePassword = (userData, connectTcp) => async (dispatch) => {
  const { token, newPassword } = userData;

  try {
      dispatch({ type: 'REQUEST_CHANGEPASSWORD' });
      await connectTcp({ token, newPassword }, 'resetpassword');
  } catch (error) {
      console.error('Error en el cambio de contraseña:', error);
      dispatch(authError('Error de conexión.'));
  }
};
  
export const requestChangePin = (userData, connectTcp) => async (dispatch) => {
  const { token, newPin } = userData;

  try {
      dispatch({ type: 'REQUEST_CHANGEPIN' });
      await connectTcp({ token, newPin }, 'resetpin');
  } catch (error) {
      console.error('Error en el cambio de pin:', error);
      dispatch(authError('Error de conexión.'));
  }
};

export const receiveRecoverySent = (message) => ({
  type: 'RECOVERY_SENT',
  payload: message,
});

export const receivePasswordResetSuccess = (message) => ({
  type: 'PASSWORD_RESET_SUCCESS',
  payload: message,
});

export const receivePinSent = (message) => ({
  type: 'PIN_SENT',
  payload: message,
});

export const receivePinResetSuccess = (message) => ({
  type: 'PIN_RESET_SUCCESS',
  payload: message,
});

export const receiveLogin = (details) => {
    console.log("Acción receiveLogin llamada con mensaje:", details);
    
    return {
      type: 'RECEIVE_LOGIN',
      payload: details,
    }

  };

export const setConnected = () => ({
  type: 'SET_CONNECTED',
});

  export const setDisconnected = () => ({
    type: 'SET_DISCONNECTED',
  });
  

  export const receiveRegister = (details) => {
    console.log("Acción receiveRegister llamada con mensaje:", details);

    return {
      type: 'RECEIVE_REGISTER',
      payload: details,
    };
  };
  

  export const authError = (error) => ({
    type: 'AUTH_ERROR',
    payload: error,
  });
  
  export const resetAuthState = () => ({
    type: 'RESET_AUTH_STATE',
  });
  
  export const clearMessages = () => ({
    type: 'CLEAR_MESSAGES',
  });

  export const resetLoginState = () => ({
    type: 'RESET_LOGIN_STATE'
  });
  
